import { useEffect, useState } from "react";
import './App.css';
import Button from "./components/Button/Button";
import Calendly from "./components/Calendly/Calendly";
import Logo from "./components/Logo/Logo";
import Modal from "./components/Modal/Modal";

function isCalendlyEvent(e) {
  return e.data.event &&
    e.data.event.indexOf('calendly') === 0;
}

function App() {
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    window.addEventListener(
      'message',
      function(e) {
        if (isCalendlyEvent(e)) {
          console.log(e.data);
          if (e.data.event === 'calendly.event_scheduled') {
            alert('Your consultation has been scheduled. Please save this information before closing the window.');
          }
        }
      }
    );
    return () => {
      window.removeEventListener(
        'message',
        function(e) {
          if (isCalendlyEvent(e)) {
            console.log(e.data);
            if (e.data.event === 'calendly.event_scheduled') {
              alert('Your consultation has been scheduled. Please save this information before closing the window.');
            }
          }
        }
      );
    };
  }, []);

  return (
    <div className="App">
      <header>
        <Logo />
        <p className="birdseed">KG 617 Street, Kigali, Rwanda</p>
      </header>
      <p className="mission-statement">
        This is Afrika at work
      </p>
      {/*
      <p className="mission-statement">
        Our mission is to restore our people to their traditional greatness through collective collaboration and technological advance
      </p>
      */}
      <Button
        styles="contact-us__button"
        handleOnClick={() => setOpenModal(!openModal)}
        text="Schedule a consultation"
      />
      <Modal
        handleClose={() => setOpenModal(!openModal)}
        isOpen={openModal}
        content={<Calendly />}
      />
      {/*<p className="quote">"A person that does not know can learn to know." ~ Afrikan proverb</p> */}
      <footer>
        <p className="birdseed">Ethiopia | Kenya | Rwanda | Tanzania</p>
        <p className="birdseed">Copyright &copy; 2021 Input Output Afrika Inc.</p>
      </footer>
    </div>
  );
}

export default App;
