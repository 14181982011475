import React from 'react';
import PropTypes from 'prop-types';

Modal.propTypes = {
  content: PropTypes.elementType.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

function Modal({isOpen, content, handleClose}) {
  if (!isOpen) return null;

  return (
    <div className="modal modal--from-right modal--is-visible">
      <header className="modal__header">
        <button className="modal__close-button" onClick={handleClose}>X</button>
      </header>
      <div className="modal__container">
        <div className="modal__content">
          {content}
        </div>
      </div>
    </div>
  );
}

export default Modal;