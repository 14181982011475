import React from 'react';
import PropTypes from 'prop-types';

Button.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  styles: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

function Button({ handleOnClick, styles, text }) {
  if (!text) return null;

  return (
    <button className={styles} onClick={handleOnClick}>{text}</button>
  );
}

export default Button;