import React from 'react';
import { InlineWidget } from "react-calendly";

function Calendly() {
  return (
    <div>
      <InlineWidget url="https://calendly.com/ali-yasin/30min?background_color=011627&text_color=ffffff&primary_color=03c04a" />
    </div>
  );
}

export default Calendly;