import React from 'react';
import PropTypes from 'prop-types';
import './Logo.css';

Logo.propTypes = {
  imageURL: PropTypes.string,
  text: PropTypes.string,
};

Logo.defaultProps = {
  imageURL: '/',
  text: 'Input Output Afrika',
}

function Logo({ imageURL, text }) {
  return (
    <h1 className="logo">
      <a href={imageURL}><span>Input Output</span> Afrika</a>
    </h1>
  );
}

export default Logo;